export function addClosable(close) {
    document.addEventListener('click', close)

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            close(event)
        }
    })
}

export function closeClosable() {
    document.removeEventListener('click', close)
}
